<ng-container *ngIf="!isOtcMarketSizeReady || !isMarketShareByRegionReady || !isChangeWeekReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div id="pdf_print_area_intage_flash_vm_full" #pdf_print_area_intage_flash_vm_full [class]="isOtcMarketSizeReady && isMarketShareByRegionReady && isChangeWeekReady ? '' : 'not-display' ">

    <div class="flex-two-ends">
        <!-- <div class="ng-section summary-section week native">
            <b>Week</b>
            <select class="form-control" (change)="onSelectedWeekChanged($event.target.value)">
                <option *ngFor="let item of weeks; index as i;" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <single-dropdown title="Week" class="single-dropdown-week"
            [items]="dropdownValueToNgSelectFormat(weeks)"
            [model] = "selectedWeek"
            (change)="onSelectedWeekChanged($event)">
        </single-dropdown>
        <comment-button class="d-flex align-items-center" style="flex-grow:1" title1="Intage VM Flash Report Comments" chartId="IntageVmFlashReport"
        [users]="users" [chatIdDependency]="[{selectedWeek}]" type="Summary Remarks"></comment-button>
        <download-button class="hide-pdf-print" title="Download PDF" (click)="downloadAsPDF('intage-flash-vm')"></download-button>
    </div>
    <p></p>
    <comment-button class="d-flex align-items-center" style="flex-grow:1" title1="Intage VM Flash Report Comments" chartId="IntageVmFlashReport"
    [users]="users" [chatIdDependency]="[{selectedWeek}]" type="Owner Comments"></comment-button>
    <p></p>
    <intage-otc-market-size-vm-ccbji [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="otcMarketSizeReady($event)"></intage-otc-market-size-vm-ccbji>
    <otc-market-share-vm-by-region [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="marketShareByRegionReady($event)"></otc-market-share-vm-by-region>
</div>