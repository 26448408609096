<ng-container *ngIf="!isScoreCardsReady || !isOtcMarketShareByPackageReady || !isOtcMarketShareByRegionReady || !isOtcMarketSizeReady || !isValueShareSummaryReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div id="pdf_print_area_intage_flash_full" #pdf_print_area_intage_flash_full [class]="isScoreCardsReady && isOtcMarketShareByPackageReady && isOtcMarketShareByRegionReady && isOtcMarketSizeReady && isValueShareSummaryReady ? '' : 'not-display' ">    
    <div id="pdf_print_area_0">
        <div class="flex-two-ends">
            <single-dropdown title="Week" class="single-dropdown-week"
            [items]="dropdownValueToNgSelectFormat(weeks)"
            [model] = "selectedWeek"
            (change)="onSelectedWeekChanged($event)">
        </single-dropdown>
        <comment-button class="d-flex align-items-center" style="flex-grow:1" title1="Intage OTC Flash Report Comments" chartId="IntageOTCFlashReport"
            [users]="users" [chatIdDependency]="[{selectedWeek}]" type="Summary Remarks"></comment-button>
        
            <download-button class="hide-pdf-print" title="Download PDF"
                (click)="downloadAsPDF('intage-flash')"></download-button>
        </div>
        <p></p>
        <comment-button title1="Intage OTC Flash Report Comments" chartId="IntageOTCFlashReport"
            [users]="users" [chatIdDependency]="[{selectedWeek}]" type="Owner Comments"></comment-button>
        <p></p>
        <intage_score_cards_flash [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="scoreCardsReady($event)"></intage_score_cards_flash>
        <intage-value-share-summary-flash [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" [intageVM]="intageDataVM" (ready)="valueShareSummaryReady($event)"></intage-value-share-summary-flash>        
    </div>

    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink>OTC Market Value Share by Category</a>
            <ng-template ngbNavContent>
                <intage-otc-market-size [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData"
                    (ready)="otcMarketSizeReady($event)"></intage-otc-market-size>
            </ng-template>
        </li>
    
        <li [ngbNavItem]="2">
            <a ngbNavLink>OTC Market Value Share by Package Size</a>
            <ng-template ngbNavContent>
                <intage-otc-market-share-by-package [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData"
                    (ready)="otcMarketShareByPackageReady($event)"></intage-otc-market-share-by-package>
            </ng-template>
        </li>
    
        <li [ngbNavItem]="3">
            <a ngbNavLink>OTC Market Value Share by Region</a>
            <ng-template ngbNavContent>
                <intage-otc-market-share-by-region [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData"
                    (ready)="otcMarketShareByRegionReady($event)"></intage-otc-market-share-by-region>
            </ng-template>
        </li>
    
        <!-- <li [ngbNavItem]="4">
            <a ngbNavLink>YTD OTC Market Value Share by Category</a>
            <ng-template ngbNavContent>
                <intage-otc-market-size-ccbji [selectedWeek]="selectedWeek" [intage]="intageData"
                    (ready)="otcMarketSizeReady($event)"></intage-otc-market-size-ccbji>
            </ng-template>
        </li> -->
    
        <li [ngbNavItem]="5">
            <a ngbNavLink>OTC Market Volume Share by Category</a>
            <ng-template ngbNavContent>
                <intage-otc-market-share-volume-ccbji [users]="users" [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData"
                    (ready)="otcMarketSizeReady($event)"></intage-otc-market-share-volume-ccbji>
            </ng-template>
        </li>
    </ul>
    
    <div [ngbNavOutlet]="nav"></div>
    
</div>