import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import * as htmlToImage from 'html-to-image';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    ApexCharts: any;
  }
}

@Component({
  selector: 'comment-button',
  templateUrl: './comment-button.component.html',
  styleUrls: ['./comment-button.component.scss']
})
export class CommentButtonComponent implements OnInit {
  @Input() type?: 'Comments' | 'Summary Remarks' | 'Owner Comments' = 'Comments';
  @Input() title1: string;
  @Input() title2?: string;
  @Input() chartId: string;
  @Input() screen?: HTMLElement;
  @Input() chartRef?: ApexCharts;
  @Input() chatIdDependency?: any;
  @Input() users: any;
  @Output() beforeScreen = new EventEmitter();
  @Output() afterScreen = new EventEmitter();
  image = '';
  allComments: Object[];
  comments: any[];
  username = this.getDisplayName(localStorage.getItem('email')) ?? ''
  newCommentText: string;
  editCommentId: string;
  editCommentText: string;
  isNonApexLoading: boolean = false;
  usermentions = [];
  receivers = [];
  emailbody = {};
  commentbody = {};
  updatecommentbody = {};
  deletecommentbody = {};
  ownerComment = 'Loading...';
  canRead: boolean = false;
  canWrite: boolean = false;
  focusLinkLoaded: boolean = false;

  constructor(private router: Router, private http: HttpClient) { }

  ngOnChanges(changes: OnChanges) {
    if (this.chatIdDependency?.[0]?.selectedDate || this.chatIdDependency?.[0]?.selectedWeek)
    {
      this.getComments(this.getChatId())
    }    
  }

  ngOnInit() {       
  }

  getChatId() {
    const base64Encoded = btoa(JSON.stringify(this.chatIdDependency))
    console.log(JSON.stringify(this.chatIdDependency))
    return `${this.chartId}[${base64Encoded}]`;
  }

  capitalizeEachWord(name: string) {
    return name.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  getDisplayName(email) {
    return this.capitalizeEachWord(email.split('@')[0].replaceAll('.', ' '));
  }

  getDisplayTime(time: string) {
    // TO-DO: human readable
    return time.split("T")[0];
  }

  wrapUsername(inputString: string) {
    const regex = /@[\w.]+/g;
    const outputString = inputString.replace(regex, function (match) {
      return `<span class="red">${match}</span>`;
    });
    return outputString
  }

  removeHiddenCharacters(inputString: string) {
    return inputString.replace(/[\u200B-\u200D\uFEFF]/g, '');
  }

  getPermissions() {
    let queryUrl = environment.hygraphCommentPermission;
    let httpOptions: Object = {
    headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
    responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
        response => {
            let res = JSON.parse(response);
            const reportPermission = res.data.permissions.find(x => x.page.url === location.pathname)
            // Set can read/write
            const senseGroup = JSON.parse(localStorage.getItem("userinfo"))[environment.customer][0].senseGroup.name
            const canWrite = reportPermission?.canWrite.some(x => x.name === senseGroup)
            if (canWrite) {
              this.canRead = true
              this.canWrite = true
            } else {
              const canRead = reportPermission?.canRead.some(x => x.name === senseGroup)
              this.canRead = canRead
            }

            // Set owner message
            const hasSelectedDateOrWeek = this.chatIdDependency?.[0]?.selectedDate || this.chatIdDependency?.[0]?.selectedWeek
            if (this.type === 'Owner Comments' && hasSelectedDateOrWeek) {
              const ownersMail = reportPermission?.owners.map(x => x.email)
              const firstCommentFromOwners = this.comments?.find(x => ownersMail.includes(x.email))?.message
              if (this.canRead) {
                if (firstCommentFromOwners) {
                  this.ownerComment = firstCommentFromOwners
                } else {
                  this.ownerComment = 'No owner comments yet'
                }
              } else {
                this.ownerComment = "You don't have permission to read this comment"
              }
            }

            // Trigger the comment modal from query string
            try {
              const focusChatId = this.router.parseUrl(this.router.url).queryParams['chatId'] || '';
              const focusChartId = this.removeHiddenCharacters(focusChatId.split('[')[0])
              if (focusChartId === this.removeHiddenCharacters(this.chartId)) {
                const $ = window["jQuery"];
                setTimeout(() => {
                  if (this.focusLinkLoaded === false) {
                    const button = $(`[data-bs-target="#commentModal_${focusChartId}"]`);
                    if(button.parents('.tab-content'.length > 0)) {
                      const tabId = button.parents('.tab-pane').attr('id')
                      button.closest('.tab-content').siblings('.nav-tabs').find(`[aria-controls="${tabId}"]`)[0]?.click()
                    }
                    button.trigger('click');
                    this.focusLinkLoaded = true;
                  }
                }, 0);
              }
            } catch (error) {
              console.log(error)
            }
        },
        error => 
        {
            console.log(error);                        
        }
    );
  }

  getComments(chatId: string) {
    let queryUrl = environment.hygraphGetComment + "?chatId=" + chatId;
    let httpOptions: Object = {
    headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
    responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
        response => {
            let res = JSON.parse(response);
            if (res.data) {
              this.comments = res.data[environment.comment].map((msg) => {
                return {
                  ...msg,
                  message: this.wrapUsername(decodeURIComponent(msg.message)),
                  rawMessage: decodeURIComponent(msg.message)
                }
              });
            }

            const userInfo = JSON.parse(localStorage.getItem("userinfo"))[environment.customer][0];
            const commentGroups = JSON.parse(localStorage.getItem("userinfo"))[environment.customer][0].commentGroups;
            const reportPermission = commentGroups.find(x => x.page.url === location.pathname);
            const canWrite = reportPermission?.canWrite;
            if (canWrite) {
              this.canRead = true;
              this.canWrite = true;
            } else {
              const canRead = reportPermission?.canRead;
              this.canRead = canRead;
            }

            // Set owner message
            const hasSelectedDateOrWeek = this.chatIdDependency?.[0]?.selectedDate || this.chatIdDependency?.[0]?.selectedWeek
            if (this.type === 'Owner Comments' && hasSelectedDateOrWeek) {
              const firstCommentFromOwners = this.comments?.find(x => reportPermission?.isOwner && x.email == userInfo.email)?.message
              if (this.canRead) {
                if (firstCommentFromOwners) {
                  this.ownerComment = firstCommentFromOwners
                } else {
                  this.ownerComment = 'No owner comments yet'
                }
              } else {
                this.ownerComment = "You don't have permission to read this comment"
              }           
            }

            // Trigger the comment modal from query string
            try {
              const focusChatId = this.router.parseUrl(this.router.url).queryParams['chatId'] || '';
              const focusChartId = this.removeHiddenCharacters(focusChatId.split('[')[0])
              if (focusChartId === this.removeHiddenCharacters(this.chartId)) {
                const $ = window["jQuery"];
                setTimeout(() => {
                  if (this.focusLinkLoaded === false) {
                    const button = $(`[data-bs-target="#commentModal_${focusChartId}"]`);
                    if(button.parents('.tab-content'.length > 0)) {
                      const tabId = button.parents('.tab-pane').attr('id')
                      button.closest('.tab-content').siblings('.nav-tabs').find(`[aria-controls="${tabId}"]`)[0]?.click()
                    }
                    button.trigger('click');
                    this.focusLinkLoaded = true;
                  }
                }, 0);
              }
            } catch (error) {
              console.log(error)
            }

            //this.getPermissions();
        },
        error => 
        {
            console.log(error);                        
        }
    );
  }

  getCommentsCount() {
    return this.comments?.length ?? 0
  }

  deleteComment(cid: string) {
    this.deletecommentbody = {
      id: cid
    };
    let queryUrl = environment.hygraphDeleteComment;
    let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.post<any>(queryUrl, this.deletecommentbody, httpOptions)
        .subscribe(
        response => {
          this.getComments(this.getChatId());
        },
        error => console.log(error)
    );
  }

  editComment(cid: string, text: string) {
    this.updatecommentbody = {
      message: encodeURIComponent(text),
      id: cid
    };

    let queryUrl = environment.hygraphUpdateComment;
    let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.post<any>(queryUrl, this.updatecommentbody, httpOptions)
        .subscribe(
        response => {
          this.getComments(this.getChatId())
          this.editCommentId = "";
          this.editCommentText = "";
        },
        error => console.log(error)
    );
  }

  loadNonApex = (timeout: number = 0) => {
    if (this.screen) {
      this.isNonApexLoading = true;
      this.beforeScreen.emit(null);
      setTimeout(() => {
        htmlToImage.toPng(this.screen, { quality: 1.0 })
          .then((dataUrl) => {
            this.image = dataUrl
            this.afterScreen.emit(null);
            this.isNonApexLoading = false;
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
            this.isNonApexLoading = false;
          });
      }, timeout);
    }
  }

  async onOpen() {
    this.editCommentId = "";
    this.editCommentText = "";

    // Load Chart Image
    if (this.chartRef) {
      try {
        const base64 = await this.chartRef.dataURI() as any;
        this.image = base64.imgURI;
      } catch (error) {

      }
    }

    if (this.isNonApexLoading === false) {
      this.loadNonApex()
    }

    this.getComments(this.getChatId())

  }

  getFocusUrl = (chatId: string) => {
    return `${location.origin}${location.pathname}?chatId=${chatId}`
  }

  onItemSelected(item: any): void {
    if (!this.usermentions.includes(item.email))
    {
      this.usermentions.push(item.email);
    }
  }

  onSubmit() {
    let queryUrl = environment.hygraphCreateComment;
    this.commentbody = {
      chatId: this.getChatId(),
      email: localStorage.getItem('email'),
      message: encodeURIComponent(this.newCommentText),
      focusUrl: this.getFocusUrl(this.getChatId())
    }
    let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.post<any>(queryUrl, this.commentbody, httpOptions)
        .subscribe(
        response => {
          this.usermentions.forEach(x => {
            var user_email = x.slice(0, x.indexOf('@'));
            if (!this.newCommentText.includes(user_email))
            {
              return;
            }    
            if (!this.receivers.some(y => y.email == x))
            {
              this.receivers.push(x);
            }
          })

          this.emailbody = {
            sender: localStorage.getItem("email"),
            receivers: this.receivers,
            message: this.newCommentText,
            // message: encodeURIComponent(this.newCommentText),
            page: location.pathname.replace("/", "").toUpperCase(),
            url: this.getFocusUrl(this.getChatId())
          };

          if (this.receivers.length > 0)
          {
            let queryUrl = environment.commentsendemail;
            let httpOptions: Object = {
                headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                responseType: 'text'
            }
            this.http.post<any>(queryUrl, this.emailbody, httpOptions)
                .subscribe(
                response => {
                  //do nothing
                },
                error => console.log(error)
            );              
          }
         
          this.usermentions = [];
          this.emailbody = {};
          this.receivers = [];

          this.getComments(this.getChatId())
          this.newCommentText = '';
        },
        error => console.log(error)
    );                    
  }

}
