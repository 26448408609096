import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userInfo: any = null;

  constructor(private http: HttpClient) {}

  getUserInfo(): any {
    if (!this.userInfo) {
      const storedData = localStorage.getItem('userinfo');

      if (storedData) {
        this.userInfo = JSON.parse(storedData);
      }
    }

    return this.userInfo?.customers?.length > 0 ? this.userInfo.customers[0]?.filterOptionsGroup?.name : '';  
  }

  GetUsers(page: string)
  {
    let queryUrl = environment.hygraphGetUsers + "?page=" + page;
    let httpOptions: Object = {
    headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
    responseType: 'text'
    }

    return new Promise((resolve, reject) => {
        this.http.get<any>(queryUrl, httpOptions)
          .subscribe(
            response => {
            const users = [];
            let res = JSON.parse(response);
            res.data[environment.customer].forEach(user => {
                users.push({
                name: user.email.split('@')[0],
                displayName: this.capitalizeEachWord(this.getDisplayName(user.email)),
                email: user.email
                })
            });
              resolve(users);
            },
            error => {
              console.log('error', error);
              reject(error);
            }
          )
      });
  }

  capitalizeEachWord(name: string) 
  {
    return name.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  getDisplayName(email) 
  {
    return this.capitalizeEachWord(email.split('@')[0].replaceAll('.', ' '));
  }
}
